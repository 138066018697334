import { SidebarContext } from "@/context/sidebar";
import { useAnalytics } from "@/hooks/useAnalytics";
import { useDimensionsHandler } from "@/hooks/useDimensionsHandler";
import type {
  MaterialIconProps,
  NavigationButtonProps,
} from "@talent-garden/react-components";
import { NavigationButton } from "@talent-garden/react-components";
import Link from "next/link";
import { useContext, type FC, type ReactElement, type ReactNode } from "react";
import styles from "./sidebar.module.scss";

interface SidebarLinkProps {
  href: string;
  active?: boolean;
  icon: MaterialIconProps["name"];
  iconVariant: MaterialIconProps["variant"];
  text: string;
  tooltipText?: string | ReactElement;
  external?: boolean;
  disabled?: boolean;
  tooltipKind?: NavigationButtonProps["tooltipKind"];
  sidebarOpen?: boolean;
}

const LinkComponent: FC<{
  external?: boolean;
  disabled?: boolean;
  href: string;
  children: ReactNode;
}> = ({ external = false, href, children, disabled = false }) => {
  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  if (external) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link passHref href={href}>
      {children}
    </Link>
  );
};

export const SidebarLink: FC<SidebarLinkProps> = ({
  href,
  active,
  icon,
  iconVariant,
  text,
  external,
  disabled = false,
  tooltipText,
  tooltipKind,
  sidebarOpen = false,
}) => {
  // const { captureInteraction } = useAnalytics();
  const { setSidebarOpen } = useContext(SidebarContext);
  const { isMobile } = useDimensionsHandler();

  return (
    <li
      className={styles.item}
      data-active={active}
      data-external={external}
      data-disabled={disabled}
    >
      <LinkComponent external={external} href={href} disabled={disabled}>
        <NavigationButton
          onClick={() => {
            const category = href.includes("support")
              ? "support"
              : href.split("/")[1];
            // captureInteraction({
            //   event_category: category,
            //   event_label: "open",
            //   section: "sidebar",
            // });
            if (isMobile) setTimeout(() => setSidebarOpen(false), 600);
          }}
          shape="rect"
          icon={icon}
          iconVariant={iconVariant}
          dimension="medium"
          capitalize={false}
          showTooltip={!sidebarOpen}
          tooltipText={tooltipText ?? text}
          isActive={active}
          disabled={disabled}
          kind={disabled ? "link-neutral" : undefined}
          tooltipKind={tooltipKind}
          withArrow
          horizontalAlign={sidebarOpen ? "start" : "center"}
        >
          {text}
        </NavigationButton>
      </LinkComponent>
    </li>
  );
};
