/*  eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
import { Flex } from "@talent-garden/react-components";
import clsx from "clsx";
import Hls from "hls.js";
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import style from "./videoPlayer.module.scss";

const WATCHED_PERCENTAGE_CHECKPOINTS = [
  { min: 25, max: 50, value: 25 },
  { min: 50, max: 75, value: 50 },
  { min: 75, max: 90, value: 75 },
  { min: 90, max: 100, value: 90 },
];

const VideoPlayer: FC<{
  url: string;
  cover?: string;
  className?: string;
  progressEventCallback?: (progress: number) => void;
}> = ({ url, cover, className, progressEventCallback, ...props }) => {
  const player = useRef<any>();
  const [watchedPercentage, setWatchedPercentage] = useState(0);
  const [progressPercentageCheckpoint, setProgressPercentageCheckpoint] =
    useState<number>(0);

  useEffect(() => {
    try {
      const video = player.current;
      const extension = url.split(".").pop();
      if (extension === "mp4") {
        video.src = url;
        video.addEventListener("loadedmetadata", () => {
          video.play();
        });
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url;
        video.addEventListener("loadedmetadata", () => {
          video.play();
        });
      }
    } catch (err) {
      console.log("Error:", err);
    }
  }, [url]);

  useEffect(() => {
    if (!url) {
      return;
    }
    try {
      player?.current?.load();
      player?.current?.play();
    } catch (err) {
      console.log("Error:", err);
    }
  }, [url]);

  const updateWatchedPercentage = () => {
    const video = player.current;
    if (video) {
      const { currentTime } = video;
      const { duration } = video;
      if (duration > 0) {
        const percent = Math.ceil((currentTime / duration) * 100);
        setWatchedPercentage(percent);
      }
    }
  };

  // workaround to trigger ph progress events even when the users skips manually
  useEffect(() => {
    const video = player.current;

    if (video) {
      // Update the watched percentage when the time updates
      video.addEventListener("timeupdate", updateWatchedPercentage);

      // Cleanup listener when the component unmounts
      return () => {
        video.removeEventListener("timeupdate", updateWatchedPercentage);
      };
    }
  }, []);

  // throw progress events

  useEffect(() => {
    const checkpoint = WATCHED_PERCENTAGE_CHECKPOINTS.find(
      ({ min, max, value }) =>
        watchedPercentage >= min &&
        watchedPercentage < max &&
        progressPercentageCheckpoint !== value,
    );
    if (checkpoint) {
      progressEventCallback?.(checkpoint.value);
      setProgressPercentageCheckpoint(checkpoint.value);
    }
  }, [watchedPercentage, progressPercentageCheckpoint]);

  return (
    <Flex className={clsx(style.container, className)} {...props}>
      <video
        controls
        className={style.video}
        controlsList="nodownload"
        ref={player}
        preload="metadata"
        autoPlay
        // muted
      >
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export default VideoPlayer;
