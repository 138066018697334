import { Alert } from "@talent-garden/react-components";
import type { MaterialIconNames } from '@talent-garden/react-components/icons/mui-types';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from "react";
import ReactDOM from "react-dom";

export interface IAlertItem {
  type: "add" | "remove" | "error";
  title: string;
  subtitle: string;
  icon?: MaterialIconNames;
}

export interface IAlertsContext {
  openAlert: (alertItem: IAlertItem) => void;
};

const DEFAULT_ALERT_ICON_MAPPING: Record<IAlertItem["type"], MaterialIconNames> = {
  add: "check_circle",
  remove: "heart_broken",
  error: "error",
};
const ALERT_TYPE_MAPPING: Record<IAlertItem["type"], "error" | "info" | "warning" | "success" | undefined> = {
  add: "success",
  remove: "info",
  error: "error",
};

interface AlertssContextProps {
  children: ReactNode;
}
export const AlertsContext = createContext<IAlertsContext>(
  {} as IAlertsContext
);

export const AlertsProvider: FC<AlertssContextProps> = ({ children }) => {
  const [openAlertItem, setOpenAlertItem] = useState<IAlertItem | null>(null);

  const handleOpenAlert = useCallback((alertItem: IAlertItem) => {
    setOpenAlertItem(alertItem);
  }, [setOpenAlertItem]);

  const onOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setOpenAlertItem(null);
    }
  }, []);

  const alertsContextValue: IAlertsContext = useMemo(() => {
    return {
      openAlert: handleOpenAlert,
    };
  }, [handleOpenAlert]);

  return (
    <AlertsContext.Provider value={alertsContextValue}>
      {children}
      {openAlertItem ? ReactDOM.createPortal(
        <div style={{ zIndex: 9999 }}>
          <Alert
            title={openAlertItem.title}
            description={openAlertItem.subtitle}
            isOpen={!!openAlertItem}
            handleOpenChange={(open: boolean) => onOpenChange(open)}
            icon={openAlertItem?.icon ?? DEFAULT_ALERT_ICON_MAPPING[openAlertItem.type]}
            withDismiss
            type={ALERT_TYPE_MAPPING[openAlertItem.type]}
            duration={4000}
            iconVariant='outlined'
          />
        </div>,
        document.body
      ) : null}
    </AlertsContext.Provider>
  );
};
