import { useQueryClient } from '@tanstack/react-query';
import { ConfirmSignUpInput, SignInInput, confirmSignUp, fetchAuthSession, signIn, signOut, signUp } from 'aws-amplify/auth';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';

type TStatus = "loading" | "unauthenticated" | "authenticated";

interface ISession {
    accessToken: string | null
}

type SignUpParameters = {
    username: string;
    password: string;
    returnUrl?: string;
};

// Define the session context type
interface SessionContextType {
    accessToken: string | null;
    handleSignIn: ({ username, password, returnUrl }: SignInInput & { returnUrl?: string }) => Promise<{ error?: any, nextStep?: any } | undefined> | undefined;
    handleSignOut: ({ callbackUrl }: { callbackUrl: string }) => void;
    handleSignUp: ({ username, password }: SignUpParameters) => Promise<{
        error?: any,
        nextStep?: any,
    } | undefined> | undefined;
    handleSignUpConfirmation: ({ username, confirmationCode, password, returnUrl }: ConfirmSignUpInput & { password: string, returnUrl?: string }) => Promise<{ error?: any, nextStep?: any } | undefined> | undefined;
    getCurrentSession: () => void;
    status: TStatus,
    data: ISession | null
}

// Create the session context
export const SessionContext = createContext<SessionContextType>({
    accessToken: null,
    handleSignIn: () => undefined,
    handleSignUp: () => undefined,
    handleSignUpConfirmation: () => undefined,
    handleSignOut: () => { },
    getCurrentSession: () => undefined,
    status: "loading",
    data: null
});

// Create the session provider component
export const SessionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [status, setStatus] = useState<TStatus>("loading");
    const [data, setData] = useState<ISession | null>(null);
    const router = useRouter();
    const queryClient = useQueryClient();

    const getCurrentSession = async () => {
        try {
            const { accessToken } = (await fetchAuthSession({ forceRefresh: true })).tokens ?? {};

            if (!accessToken) {
                throw new Error("Access token not found")
            }
            setStatus("authenticated");
            setData({ accessToken: accessToken?.toString() })
        } catch (err) {
            setStatus("unauthenticated");
        }
    }

    useEffect(() => {
        getCurrentSession()
    }, [])

    // Function to handle login
    const handleSignIn = useCallback(async ({ username, password, returnUrl }: SignInInput & { returnUrl?: string }) => {
        try {
            const { isSignedIn, nextStep } = await signIn({
                username,
                password,
                // options: {
                //     authFlowType: 'USER_PASSWORD_AUTH'
                // }
            });

            if (isSignedIn) {
                await getCurrentSession();
                // store is the only page that should avoid redirecting to welcome because is there a logic inside
                router.push((returnUrl?.includes("store") ? returnUrl : `/welcome${returnUrl ? `?returnUrl=${returnUrl}` : ""}`) as string);
            } else if (nextStep) {
                return {
                    nextStep: { ...nextStep, username },
                }
            }
        } catch (err: any) {
            return {
                error: err?.message
            }
        }
    }, [router.locale]);

    // Function to handle signup
    const handleSignUp = useCallback(async ({ username, password }: SignUpParameters) => {
        try {
            const { isSignUpComplete, userId, nextStep } = await signUp({
                username,
                password,
            });

            return {
                isSignUpComplete,
                nextStep: { ...nextStep, username },
                userId
            }
        } catch (err: any) {
            console.log('error signing up:', err);
            return {
                error: err?.message
            }
        }
    }, []);

    const handleSignUpConfirmation = useCallback(async ({
        username,
        confirmationCode,
        password,
        returnUrl
    }: ConfirmSignUpInput & { password: string, returnUrl?: string }) => {
        try {
            const { isSignUpComplete, nextStep } = await confirmSignUp({
                username,
                confirmationCode
            });

            if (isSignUpComplete) {
                try {
                    await handleSignIn({ username, password, returnUrl })
                } catch (error: any) {
                    return {
                        error: error?.message
                    }
                }
            }

            return {
                nextStep: { ...nextStep, username }
            }
        } catch (err: any) {
            console.log('error signing up:', err);
            return {
                error: err?.message
            }
        }
    }, [])


    // Function to handle logout
    const handleSignOut = useCallback(async ({
        callbackUrl
    }: { callbackUrl: string }) => {
        // Add your logout logic here
        try {
            await signOut();
            setAccessToken(null);
            setStatus("unauthenticated");
            // Redirect
            await router.push((callbackUrl ?? "/") as string);
            setCookie("selectedWorkspaceId", "");
            await queryClient.invalidateQueries();
            queryClient.removeQueries({ queryKey: ["owner-profile"], exact: true });
            posthog.reset()
        } catch (err) {
            console.error('Error logging out: ', err);
            return {
                error: err
            }
        }
    }, []);

    const values = useMemo(() => ({ accessToken, handleSignIn, handleSignOut, handleSignUp, handleSignUpConfirmation, status, data, getCurrentSession }), [accessToken, handleSignIn, handleSignOut, handleSignUp, handleSignUpConfirmation, status, data, getCurrentSession])

    return (
        <SessionContext.Provider value={values}>
            {children}
        </SessionContext.Provider>
    );
};
