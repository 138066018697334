import type { FC } from "react";
import { useEffect, useState } from "react";

import { validateVat } from "@/lib/vat";
import {
  Flex,
  GoogleAutocomplete,
  Text,
} from "@talent-garden/react-components";
import { useTranslation } from "next-i18next";
import { useFormContext } from "react-hook-form";
import { BillingTextField } from "../inputs";
import styles from "./forms.module.scss";

const CompanyForm: FC = () => {
  const {
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useFormContext();
  const { t } = useTranslation(["inputs"]);
  const [country] = watch(["customer.country"]);
  const [formattedAddress] = watch(["customer.formattedAddress"]);
  const [vat] = watch(["customer.vat"]);
  const [vatBlur, setVatBlur] = useState(false);

  useEffect(() => {
    if (!vatBlur) {
      return;
    }
    // if (!country /* !EuropeCountries?.includes(country) */) {
    //   return;
    // }
    if (!vat || (errors?.customer as any)?.vat) {
      // TODO: tipizzare
      return;
    }

    const getCompanyDetails = async (value: string) => {
      try {
        const { data: validatedData } = await validateVat(value);

        if (country !== "IT" && !validatedData?.valid) {
          return;
        }
        if (country === "IT" && !validatedData?.format_valid) {
          return;
        }

        if (validatedData?.company_name) {
          setValue("customer.companyName", validatedData?.company_name, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
          });
        }
        // if (validatedData?.country_code) {
        //   setValue("customer.country", validatedData?.country_code, {
        //     shouldDirty: true,
        //     shouldValidate: true,
        //     shouldTouch: true,
        //   });
        // }
      } catch (err) {
        console.error(err);
      } finally {
        setVatBlur(false);
      }
    };

    getCompanyDetails(vat);
  }, [setValue, vat, errors, country, vatBlur]);

  return (
    <Flex rowGap={8} className={styles.inputsContainer}>
      <Flex direction="column" className={styles.formChild}>
        <BillingTextField
          label={t("vat-number") as string}
          placeholder={
            t("insert-vat-number", { country: country || "AA" }) as string
          }
          name="vat"
          uppercase
          onBlur={() => {
            setVatBlur(true);
          }}
        />
        <BillingTextField
          label={t("company-name") as string}
          placeholder={t("insert-company-name") as string}
          name="companyName"
        />
      </Flex>

      <Flex direction="column" className={styles.formChild}>
        <BillingTextField
          label={t("edit-first_name") as string}
          placeholder={t("insert-first_name") as string}
          name="firstName"
        />
        <BillingTextField
          label={t("edit-last_name") as string}
          placeholder={t("insert-last_name") as string}
          name="lastName"
        />
      </Flex>
      <Flex direction="column" className={styles.formChild}>
        {/* Country or region */}
        <GoogleAutocomplete
          defaultValue={formattedAddress}
          className={styles.fullwidth}
          getLocation={(location) => {
            if ((errors?.customer as any)?.formattedAddress) {
              clearErrors("customer.formattedAddress");
              clearErrors("customer.country");
              clearErrors("customer.address");
            }
            const streetNumber = location?.address_components?.find(
              (addr: any) => addr.types.includes("street_number"), // TODO: @matte tipizzare
            )?.long_name;

            if (!streetNumber) {
              setError("customer.formattedAddress", {
                type: "manual",
                message: "missing-street-number",
              });
            }
            setValue("customer.formattedAddress", location?.formatted_address);
            setValue("customer.address", location?.address_components);

            // eslint-disable-next-line no-shadow
            const country = location?.address_components?.find(
              (
                addr: any, // TODO: @matte tipizzare
              ) => addr.types.includes("country"),
            )?.short_name;

            if (country) {
              setValue("customer.country", country);
              // // if the country of the address is different from the vat > show error
              // if (country?.toLowerCase() !== vat?.slice(0, 2)?.toLowerCase()) {
              //   setError("customer.formattedAddress", {
              //     type: "manual",
              //     message: "vat-country-match",
              //   });
              // } else {
              // setValue("customer.country", country);
              // }
            }
          }}
          label={t("edit-address") as string}
          placeholder={t("insert-company-address") as string}
          googlePlacesOptions={{
            types: ["address"],
          }}
          // defaultValue={address}
          errorMessage={
            errors && (errors?.customer as any)?.formattedAddress // TODO: @matte tipizzare
              ? (t(
                  `${(errors?.customer as any)?.formattedAddress?.message}`, // TODO: @matte tipizzare
                ) as string)
              : undefined
          }
        />
      </Flex>
      {country === "IT" ? (
        <>
          <Flex direction="column">
            <BillingTextField
              label={t("edit-company-fiscal_code") as string}
              placeholder={t("insert-fiscal_code") as string}
              name="fiscalCode"
              uppercase
              fullWidth
            />
            <Text variant="s" weight="semibold" className={styles.warning}>
              {t("buy:warning-fiscal-code")}
            </Text>
          </Flex>
          <Flex columnGap={8} direction="column" className={styles.formChild}>
            <BillingTextField
              label={t("pec-email") as string}
              placeholder={t("insert-pec-email") as string}
              name="pecEmail"
            />
            <BillingTextField
              label={t("sdi-code") as string}
              placeholder={t("insert-sdi-code") as string}
              name="sdi"
              uppercase
            />
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

export default CompanyForm;
