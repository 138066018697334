import type { IPaymentCard, TInvoice } from "../types";
import { capitalizeFirstLetter } from "./utils";

const BRAND_WITH_IMAGE = ["visa", "mastercard", "amex", "paypal"];

export const removeEmpty = (obj: object) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const denormalizeBillingProfile = (
  data: any /* BillingProfileSchema */,
) => {
  return removeEmpty({
    billing_email: data?.customer?.billingEmail,
    phone_number: data?.customer?.phoneNumber
      ? data?.customer?.phoneNumber.replace(/\s+/g, "")
      : null,
    phone_prefix: data?.customer?.phonePrefix,
    ...(!data?.isIndividual && {
      country: data?.customer?.country,
      company_name: data?.customer?.companyName,
      vat: data?.customer?.vat?.toUpperCase(),
      sdi_code: data?.customer?.sdi,
      pec_email: data?.customer?.pecEmail,
      address: data?.customer?.address,
      first_name: data?.customer?.firstName,
      last_name: data?.customer?.lastName,
      fiscal_code: data?.customer?.fiscalCode?.toUpperCase(),
    }),
    ...(data?.isIndividual && {
      first_name: data?.customer?.firstName,
      last_name: data?.customer?.lastName,
      country: data?.customer?.country,
      address: data?.customer?.address,
      fiscal_code: data?.customer?.fiscalCode?.toUpperCase(),
    }),
  });
};

export const normalizeCard = (paymentCard: IPaymentCard) => {
  let brand = paymentCard?.card?.brand as string;
  let last4 = paymentCard?.card?.last4;
  let expMonth = paymentCard?.card?.exp_month;
  let expYear = paymentCard?.card?.exp_year;

  if (paymentCard?.type === "sepa_debit") {
    brand = "Sepa debit";
    last4 = paymentCard?.sepa_debit?.last4;
    expMonth = paymentCard?.sepa_debit?.exp_month;
    expYear = paymentCard?.sepa_debit?.exp_year;
  }

  return {
    id: paymentCard?.id,
    type: paymentCard?.type,
    default: paymentCard?.default,
    brand,
    last4,
    imageSrc: BRAND_WITH_IMAGE.includes(brand)
      ? `/images/payment_methods/${brand
          ?.toLowerCase()
          ?.split(" ")
          .join("_")}.svg`
      : `/images/payment_methods/generic_card.svg`,
    formattedText: capitalizeFirstLetter(`${brand} **** ${last4}`),
    expMonth: expMonth?.toString().padStart(2, "0"),
    expYear,
  };
};

export const normalizeInvoice = (invoice: TInvoice) => {
  const brand =
    invoice?.payment_method?.type === "paypal"
      ? "paypal"
      : (invoice?.payment_method?.brand as string);
  let formattedText = "";

  if (brand === "paypal") {
    formattedText = "PayPal";
  }

  if (invoice?.payment_method?.type === "card") {
    const last4 = invoice.payment_method?.last4;
    formattedText = capitalizeFirstLetter(`${brand} **** ${last4}`)!;
  }

  return {
    ...invoice,
    payment_method: {
      ...invoice?.payment_method,
      formattedText,
      imageSrc: BRAND_WITH_IMAGE.includes(brand)
        ? `/images/payment_methods/${brand
            ?.toLowerCase()
            ?.split(" ")
            .join("_")}.svg`
        : null,
    },
  };
};
export type TNormalizedInvoice = ReturnType<typeof normalizeInvoice>;
