/* eslint-disable import/no-cycle */
import { CustomerIOTracker } from "@/components/common/customerIOTracker";
import { AnalyticsContextProvider } from "@/context/analytics";
import { SessionProvider } from "@/context/session";
import { SidebarContextProvider } from "@/context/sidebar";
import { UserContextProvider } from "@/context/user";
import { WorkspaceContextProvider } from "@/context/workspace";
import { isBrowser } from "@/lib/utils";
import "@/styles/globals.scss";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import "@talent-garden/react-components/main.css";
import "@talent-garden/react-components/theme.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import { AnimatePresence } from "framer-motion";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import type { AppProps } from "next/app";
import { type PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useMemo, useRef } from "react";
import "react-image-crop/dist/ReactCrop.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/virtual";
import { ClientOnly, CustomHead } from "../components";

import SEO from "../next-seo.config";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { CognitoConfig } = require("@/lib/auth/amplifyConfiguration");

Amplify.configure(CognitoConfig, { ssr: true });

const App = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: true,
          staleTime: 10 * (60 * 1000), // 10 mins
          cacheTime: 15 * (60 * 1000), // 15 mins
          retry: 1,
        },
      },
    }),
  );

  const PostHogContainer = useMemo(() => {
    if (!isBrowser()) {
      return <Component {...pageProps} />;
    }

    return (
      <PostHogProvider
        apiKey={process.env.POSTHOG_KEY}
        options={{
          // api_host:
          //   "https://sphere-git-fix-posthog-talent-garden.vercel.app/ingest"
          api_host: `${window.location.origin}/ingest`,
          // ui_host: process.env.POSTHOG_UI_HOST,
          capture_pageview: false,
          capture_pageleave: true,
          capture_performance: true,
          autocapture: false,
          persistence: "localStorage+cookie",
          custom_campaign_params: [
            "utm_creator_id",
            "utm_resource_id",
            "utm_domain",
            "utm_test",
            "adv_campaign",
            "adv_source",
            "adv_medium",
            "adv_creator",
          ],
          loaded: (posthog_instance: PostHog) => {
            if (process.env.ENVIRONMENT === "production") {
              return;
            }
            posthog_instance.debug();
          },
        }}
      >
        <Component {...pageProps} />
        <ClientOnly>
          <CustomerIOTracker />
        </ClientOnly>
      </PostHogProvider>
    );
  }, [pageProps]);

  return (
    <QueryClientProvider client={queryClient.current}>
      <GTMProvider
        state={{ id: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_API_KEY! }}
      >
        <SessionProvider>
          <UserContextProvider>
            <WorkspaceContextProvider>
              <AnalyticsContextProvider>
                <SidebarContextProvider>
                  <AnimatePresence
                    mode="wait"
                    initial={false}
                    onExitComplete={() => window.scrollTo(0, 0)}
                  >
                    <DefaultSeo {...SEO} />
                    <CustomHead />
                    {PostHogContainer}
                  </AnimatePresence>
                </SidebarContextProvider>
              </AnalyticsContextProvider>
            </WorkspaceContextProvider>
          </UserContextProvider>
        </SessionProvider>
      </GTMProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default appWithTranslation(App);
